//React
import React, { useEffect } from "react";
//Redux
import { useSelector, useDispatch } from "react-redux";
//Actions
import { getAllUserList as getAllUserListAction, deleteUserList as deleteUserListAction, editUserList } from "actions/userList";
// Components
import Button from "../../components/Button/Button";
import UserListEdit from "../../containers/UserList/EditUserList";
import ImportButton from "../../components/ImportCSV/importButton";

// i18n
import { FormattedMessage } from "react-intl"; 
import globalMessages from "containers/messages"
import messages from "../../containers/messages";
//Utils
import { navigate } from "gatsby";
import styled from "styled-components";
import theme from "theme";
import Icon from "utils/icon";
import TopBarLayout from "../../containers/Layout/Topbar/topBar";
import UserLogged from "../../containers/userLogged";

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
`;

const Return = styled.div`
  padding: 16px 32px;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 80px;
  justify-content: space-between;
  color: ${theme.darkgray};

  @media(max-width: 768px) {
    padding: 16px;
  }
`;

const WrapButtonAdd = styled.div`
  padding: 0 0 16px 32px;
  border-bottom: 1px solid ${theme.ui.grey};

  @media(max-width: 768px) {
    padding: 0 0 16px 16px;
  }
`;

const DisplayUserList = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 75%;
  overflow-y: auto;
`;

export const ListContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${theme.ui.grey};
  padding: 16px 32px;

  &:last-child {
    border-bottom: 0px;
  }

  @media(max-width: 768px) {
    padding: 16px;
    align-items: center;
  }
`;

const ListInfos = styled.div`
  display: flex;
  flex-flow: column;
  color: ${theme.darkgray};
  width: 50%;

  @media(max-width: 768px) {

    & > span {
    }
  }
`;

const ListActions = styled.div`
  display: flex;
`;

const ListName = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`;


const EditList = styled.div`
  background-color: ${theme.ui.blue};
  padding: 8px;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;

  @media(max-width: 768px) {
    & > span {
      display: none;
    }
  }
`;
const EditSpan = styled.span`
  margin-left: 8px;
`

const DeleteList = styled.div`
  background-color: ${theme.red};
  padding: 8px;
  color: white;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin-left: 8px;
  cursor: pointer;

  & > span {
    margin-left: 8px;
  }

  @media(max-width: 768px) {
    & > span {
      display: none;
    }
  }
`;

const DeleteSpan = styled.span`
  margin-left: 8px;
`

const PageActive = styled.div`
  border: 1px solid ${theme.ui.blue};
  color: ${theme.ui.blue};
  border-radius: 5px;
  height: 32px;
  width: 32px;
  margin-left: 16px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
    background-color: ${theme.ui.blue};
    color: ${theme.white};
  }
`;

const SlideBarBackground = styled.div`
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  position: absolute;
  transition: all 0.3s ease-in-out;
  background-color: ${(props) => (props.open ? "rgba(9,67,103,.3)" : "")};
  z-index: ${(props) => (props.open ? "2" : "-1")};
`;

const Page = styled.div`
  margin: 0 12px;
`;

const UserList = () => {
  const [addNewUserList, setAddNewUserList] = React.useState(false);
  const [selectedUserList, setSelectedUserList] = React.useState(null);
  const [userListFile, setUserListFile] = React.useState(null);
  const [fileTitle, setFileTitle] = React.useState('');

  //Bind State
  const { userList } = useSelector((state) => state.userList);

  //Bind actions
  const dispatch = useDispatch();
  const getAllUserList = () => dispatch(getAllUserListAction());
  const deleteUserList = (userListId) => dispatch(deleteUserListAction(userListId));
  const update = (id, title, users, desc, admins, secureGroups) => dispatch(editUserList(id, title, users, desc, admins, secureGroups));

  const openEditUserList = (listId) => {
    setAddNewUserList(true);
    setSelectedUserList(listId);
  };

  const close = () => {
    setAddNewUserList(false);
    setSelectedUserList(null);
  };

  //UseEffect
  useEffect(() => {
    getAllUserList();
  }, []);

  return (
    <UserLogged>
      <Wrapper>
        <TopBarLayout />        
        <Return onClick={() => navigate("/admin")}>
          <Icon icon="LeftArrow" size="sm"/>
          <FormattedMessage {...globalMessages.return} />
        </Return>
        <WrapButtonAdd>
          <Button action={() => setAddNewUserList(true)}><FormattedMessage {...messages.userlist_title} /></Button>
        </WrapButtonAdd>
        <DisplayUserList>
          {userList &&
            userList.map((list) => {
              return (
                <ListContainer key={list.id}>
                  <ListInfos>
                    <ListName>{list.name}</ListName>
                  </ListInfos>
                  <ListActions>
                    <ImportButton 
                      userListId={list.id}
                      userListFile={userListFile} 
                      setUserListFile={setUserListFile}
                      userListTitle={list.name}
                      setUserListTitle={setFileTitle}
                      updateUserList={update}
                    />
                    <EditList onClick={() => openEditUserList(list.id)}>
                      <Icon icon="EditPen" size="sm"/>
                      <EditSpan><FormattedMessage {...messages.userlist_edit} /></EditSpan>
                    </EditList>
                    <DeleteList onClick={() => deleteUserList(list.id)}>
                      <Icon icon="Bin" size="sm"/>
                      <DeleteSpan><FormattedMessage {...messages.userlist_delete} /></DeleteSpan>
                    </DeleteList>
                  </ListActions>
                </ListContainer>
              );
            })}
        </DisplayUserList>
        {/* <Paginate>
          <Icon icon="ArrowLeft1" size="sm" />
          <PageActive>1</PageActive>
          <Page>2</Page>
          <Page>3</Page>
          <Icon icon="ArrowRight1" size="sm" />
        </Paginate> */}
        <SlideBarBackground open={addNewUserList} />
        {
          addNewUserList && <UserListEdit close={close} userListId={selectedUserList} />
        }
      </Wrapper>
    </UserLogged>
  );
};

export default UserList;
