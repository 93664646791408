import React, { useState } from "react";
import styled from "styled-components";

// components
import { Input } from "../../containers/Layout/SidePage";
import Button from "../Button/Button";

// utils
import theme from "theme"
import { FormattedMessage } from "react-intl";
import messages from "../../containers/messages";
import Icon from "utils/icon";

const ImportDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: baseline;
  padding-right: 8px;
`;

const Import = styled.div`
  display: flex;
  background-color: ${theme.ui.blue};
  padding: 8px;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  gap: 8px;
`;

const IconStyled = styled(Icon)`
filter: brightness(0) invert(1);
`;

const SubmitDiv = styled.div`
  display: flex;
  gap: 8px;
  align-items: baseline;
`;

const Error = styled.div`
  color: red;
`;

const ImportButton = ({ userListTitle, userListId, userListFile, setUserListFile, updateUserList }) => {
  const [importError, setImportError] = useState(false);
  const fileReader = new FileReader();

  const reset = () => {
    setUserListFile('');
  }

  const importUserListOnChange = (e) => {
    userListFile = e.target.files[0];
    userListSubmit(e)
  }

  const userListCSVToArray = userCSV => {
    try {
      const csvHeader = userCSV.slice(0, userCSV.indexOf("\n")).split(",");
      const csvRows = userCSV.slice(userCSV.indexOf("\n") + 1).split("\n");
      
      const newUserListArray = csvRows.map(user => {
        const values = user.split(",");
        const obj = csvHeader.reduce((object, header, index) => {
          object[header] = values[index];
          return object;
        }, {});
        return obj;
      });
      if (newUserListArray.length > 0) {
        let error = false;
        newUserListArray.forEach(user => {
          if (user.mail && user.username){
            error = false;
          } else {
            error = true;
          }
        });
        setImportError(error)
        if (error == false){
          updateUserList(userListId, userListTitle, newUserListArray);
          reset();
        }
      }
    }
    catch {}
  }

  const userListSubmit = (e) => {
    e.preventDefault();
    if (userListFile) {
      fileReader.onload = (e) => {
        const listUsersCSV = e.target.result;
        userListCSVToArray(listUsersCSV);
      };
      fileReader.readAsText(userListFile);
    }
  }



  return(
    <ImportDiv>
      {
        importError&&
          <Error>
            <FormattedMessage {...messages.userlist_import_error} />
          </Error>
      }
      <label>
        <Input type={"file"} accept={".csv"} onChange={(e) => importUserListOnChange(e)} hidden />
        <Import>
          <IconStyled icon="Upload" size="sm"/>
          <FormattedMessage {...messages.userlist_import_button} />
        </Import>
      </label>      
    </ImportDiv>
  );
}
export default ImportButton;